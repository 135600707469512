<template>
  <div class="economia-page">
    <v-layout row wrap>
      <v-flex xs12 sm12 md6>
        <div class="logo">
          <img id="logo-color" src="@/assets/img/logo.svg" scale="0" />
        </div>
      </v-flex>
      <v-flex
        xs12
        sm12
        md6
        class="text-xs-right hidden-sm-and-down"
        style="padding-top: 20px; padding-right: 96px"
      >
        <v-btn
          class="enercred-logo_btn"
          color="tertiary"
          outline
          type="submit"
          href="https://enercred.com.br/simulador"
          target="_blank"
        >Simular</v-btn>
      </v-flex>
    </v-layout>
    <div class="bg--white">
      <section class="enercred-indicacao">
        <v-container fluid grid-list-md fill-height>
          <v-layout row wrap>
            <v-flex xs12 sm12 md6 class="enercred-indicacao_left">
              <div class="enercred-indicacao_left text-xs-center">
                <h1
                  class="mb--micro text--jumbotron text--center lg:text--left enercred-indicacao_title"
                >Como é bom ser indicado por um amigo.</h1>
                <p
                  class="text--large mb--small text--center lg:text--left"
                >Ainda mais quando é para economizar...</p>
                <!-- <p
                  class="text--large mb--small text--center lg:text--left enercred-indicacao_ganhar hidden-sm-and-down"
                >Ao assinar você e seu amigo ganham R$15,00 na próxima fatura.</p> -->
                <v-btn
                  class="enercred-indicacao_btn"
                  color="success"
                  round
                  large
                  type="submit"
                  href="#como-funciona"
                >COMO FUNCIONA</v-btn>
              </div>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <div class="enercred-indicacao_right text-xs-center">
                <img src="@/assets/img/convidar-amigos-azul.svg" width="50%" />
                <!-- <p
                  class="text--large mb--small text--center lg:text--left enercred-indicacao_ganhar hidden-sm-and-up"
                >Ao assinar você e seu amigo ganham R$15,00 na próxima fatura.</p> -->
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
      <section class="enercred-assinatura" id="como-funciona">
        <v-container fluid grid-list-md fill-height>
          <v-layout row wrap>
            <v-flex xs12 sm12 md6>
              <div class="enercred-assinatura_left text-xs-center">
                <iframe
                  class="enercred-assinatura_iframeContainer"
                  src="https://www.youtube.com/embed/Qa1QUEaklys?start=6"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </v-flex>
            <v-flex xs12 sm12 md6 class="enercred-assinatura_rigth">
              <div class="enercred-assinatura_right text-xs-center">
                <h1
                  class="mb--micro text--jumbotron text--center lg:text--left"
                >Ecomize na sua conta de energia.</h1>
                <p
                  class="text--large mb--small text--center lg:text--left"
                >Utilizando Energia Solar. Sem investimento e sem obras</p>
                <v-btn
                  class="enercred-assinatura_btn"
                  color="success"
                  round
                  large
                  type="submit"
                  @click.prevent="goToSign"
                >ASSINAR AGORA</v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
      <section class="enercred-beneficios">
        <!-- <v-container fluid grid-list-md fill-height> -->
        <v-layout row align="center" justify="center" wrap>
          <v-flex xs12 sm12 md6 style="text-align: center">
            <div class="enercred-beneficios_left">
              <v-layout row align="center" class="enercred-beneficios_line" wrap>
                <v-flex xs12 sm12 md12 style="text-align: center">
                  <h2>Energia Limpa para você</h2>
                </v-flex>
              </v-layout>
              <v-layout row align="center" class="enercred-beneficios_line" wrap>
                <v-flex xs2 sm2 md2 style="text-align: center">
                  <v-icon class="enercred-beneficios_icon" large>mdi-office-building</v-icon>
                </v-flex>
                <v-flex
                  xs10
                  sm10
                  md10
                  style="text-align: left"
                >Para quem mora em casa, apartamento ou de aluguel</v-flex>
              </v-layout>
              <v-layout row align="center" class="enercred-beneficios_line" wrap>
                <v-flex xs2 sm2 md2 style="text-align: center">
                  <v-icon class="enercred-beneficios_icon" large>mdi-circle-slice-7</v-icon>
                </v-flex>
                <v-flex
                  xs10
                  sm10
                  md10
                  style="text-align: left"
                >Você aluga um percentual de nossa usina e nós cuidamos para que o desconto chegue em sua conta de luz.</v-flex>
              </v-layout>
              <v-layout row align="center" class="enercred-beneficios_line" wrap>
                <v-flex xs2 sm2 md2 style="text-align: center">
                  <v-icon class="enercred-beneficios_icon" large>mdi-credit-card</v-icon>
                </v-flex>
                <v-flex
                  xs10
                  sm10
                  md10
                  style="text-align: left"
                >Comodidade e segurança: Faturamos no seu cartão de crédito ou boleto apenas após receber o desconto em sua conta de energia.</v-flex>
              </v-layout>
              <v-layout row align="center" class="enercred-beneficios_line" wrap>
                <v-flex xs2 sm2 md2 style="text-align: center">
                  <v-icon class="enercred-beneficios_icon" large>mdi-solar-power</v-icon>
                </v-flex>
                <v-flex
                  xs10
                  sm10
                  md10
                  style="text-align: left"
                >E ainda você ajuda o planeta usando energia 100% Solar.</v-flex>
              </v-layout>
              <v-layout row align="center" class="enercred-beneficios_line" wrap>
                <v-flex xs12 sm12 md12 style="text-align: center">
                  <h3>Corra! As vagas são limitadas.</h3>
                  <v-btn
                    class="enercred-assinatura_btn"
                    color="success"
                    round
                    large
                    type="submit"
                    @click.prevent="goToSign"
                  >ASSINAR AGORA</v-btn>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
          <v-flex xs12 sm12 md6 style="text-align: center">
            <div class="enercred-beneficios_right">
              <v-carousel height="100vh">
                <v-carousel-item
                  v-for="(item,i) in items"
                  :key="i"
                  :src="item.src"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                >
                  <div class="carousel_name">
                    <p>{{item.name}}</p>
                  </div>
                </v-carousel-item>
              </v-carousel>
            </div>
          </v-flex>
        </v-layout>
        <!-- </v-container> -->
      </section>
      <section class="enercred-clientes">
        <!-- <v-container fluid grid-list-md fill-height> -->
        <v-layout row wrap>
          <v-flex xs12 sm12 md12>
            <div class="enercred-clientes_left text-xs-center">
              <h2
                class="mb--micro text--jumbotron text--center lg:text--left"
              >Veja o que nossos clientes estão falando...</h2>
              <iframe
                class="enercred-clientes_videos"
                src="https://www.youtube.com/embed/aTF-JUDkk08"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <iframe
                class="enercred-clientes_videos"
                src="https://www.youtube.com/embed/0PVzZVgoolM"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <iframe
                class="enercred-clientes_videos"
                src="https://www.youtube.com/embed/JKsy6lf3Cl4"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <v-btn
                class="enercred-clientes_btn"
                color="white"
                outline
                round
                large
                type="submit"
                @click.prevent="goToSign"
              >ALUGAR AGORA</v-btn>
            </div>
          </v-flex>
        </v-layout>
        <!-- </v-container> -->
      </section>
      <section class="enercred-footer">
        <!-- <v-container fluid grid-list-md fill-height> -->
        <v-layout row wrap>
          <v-flex xs12 sm12 md12>
            <div class="text-xs-center">Enercred © 2020</div>
          </v-flex>
        </v-layout>
        <!-- </v-container> -->
      </section>
    </div>
  </div>
</template>
<script>
import { saveAnalytics } from "@/services/save_analytics"
export default {
  data() {
    return {
      referral_code: "",
      items: [
        {
          src: "https://www.enercred.com.br/wp-content/themes/enercred-1/assets/min-images/about/stations/1.jpg",
          name: "Planta Pedralva II"
        },
        {
          src: "https://www.enercred.com.br/wp-content/themes/enercred-1/assets/min-images/about/stations/2.jpg",
          name: "Planta Pedralva II"
        },
        {
          src: "https://www.enercred.com.br/wp-content/themes/enercred-1/assets/min-images/about/stations/4.jpg",
          name: "Planta Pedralva I"
        }
      ]
    }
  },
  created() {
    this.isProd = process.env.NODE_ENV === "production"
    if (this.$route.query.referral_code) {
      this.referral_code = this.$route.query.referral_code
      // console.log("referral_code: ", this.referral_code)
    }
    if (this.isProd) {
      const analytics = {
        eventCategory: "onboard",
        eventAction: "VIEWED_LP_ONBOARDING",
        eventLabel: "VIEWED_LP_ONBOARDING",
        value: ""
      }
      if(this.$route.query.referral_code) {
        analytics.referral_code = {
          value: 30,
          action: 'REFERRAL_VIEW_LP',
          category: 'REFERRAL',
          indicated: '',
          indicator: this.$route.query.referral_code
        }
      }
      saveAnalytics(analytics)
    }
    this.getUTM()
  },
  methods: {
    getCookie(cname) {
      var name = cname + "="
      var ca = document.cookie.split(";")
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == " ") {
          c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ""
    },
    getUTM() {
      if (this.$route.query.utm_source) {
        this.utm_source = this.$route.query.utm_source
        localStorage.setItem("utm_source", this.utm_source)
        // } else if (window.$cookies.isKey('utm_source')) {
      } else if (this.getCookie("utm_source")) {
        this.utm_source = this.getCookie("utm_source")
        localStorage.setItem("utm_source", this.utm_source)
      } else if (localStorage.getItem("utm_source")) {
        this.utm_source = localStorage.getItem("utm_source")
      } else {
        this.utm_source = "direct"
      }

      if (this.$route.query.utm_medium) {
        this.utm_medium = this.$route.query.utm_medium
        localStorage.setItem("utm_medium", this.utm_medium)
        // } else if (window.$cookies.isKey('utm_medium')) {
      } else if (this.getCookie("utm_medium")) {
        this.utm_medium = this.getCookie("utm_medium")
        localStorage.setItem("utm_medium", this.utm_medium)
      } else if (localStorage.getItem("utm_medium")) {
        this.utm_medium = localStorage.getItem("utm_medium")
      } else {
        this.utm_medium = "direct"
      }

      if (this.$route.query.utm_campaign) {
        this.utm_campaign = this.$route.query.utm_campaign
        localStorage.setItem("utm_campaign", this.utm_campaign)
        // } else if (window.$cookies.isKey('utm_campaign')) {
      } else if (this.getCookie("utm_campaign")) {
        this.utm_campaign = this.getCookie("utm_campaign")
        localStorage.setItem("utm_campaign", this.utm_campaign)
      } else if (localStorage.getItem("utm_campaign")) {
        this.utm_campaign = localStorage.getItem("utm_campaign")
      } else {
        this.utm_campaign = "direct"
      }
    },
    goToSign() {
      //   this.$router.replace({"cadastro-assinatura", );
      if (this.referral_code) {
        this.$router.push({ path: "cadastro", query: { referral_code: this.referral_code } })
      } else {
        this.$router.push({ path: "cadastro" })
      }
    }
  }
}
</script>
<style scoped>
.logo {
  background-color: #fff;
  padding: 30px 30px;
}

#logo-color {
  max-width: 130px;
  width: 130px;
}

p {
  margin: 0;
}

.economia-page {
  background-color: #fff;
  color: #2b2f45;
  font-family: "Poppins", "Helvetica Neue", Calibre, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  /* padding-left: 32px;
        padding-right: 32px; */
}

.economia-page .bg--white {
  background-color: #fff;
  height: 100vh;
}

.enercred-indicacao {
  background-color: #fff;
  /* display: -ms-flexbox; */
  display: flex;
  /* color: #FFF; */
  /* -ms-flex-direction: column; */
  flex-direction: column;
  /* -ms-flex-pack: center; */
  justify-content: center;
}

.enercred-assinatura {
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-direction: column; */
  flex-direction: column;
  /* -ms-flex-pack: center; */
  justify-content: center;
}

.enercred-assinatura_iframeContainer {
  max-width: 560px;
  width: 100%;
  height: 199px;

  /* margin-bottom: -8%; */
}

.enercred-assinatura_btn {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  max-width: 90%;
}

.economia-page .text--center {
  text-align: center;
}
.economia-page .text--jumbotron {
  font-size: 64px;
  font-weight: 700;
  line-height: 72px;
}

.economia-page .text--jumbotron,
.economia-page h1,
.economia-page .h1,
.economia-page h2,
.economia-page .h2,
.economia-page h3,
.economia-page .h3,
.economia-page h4,
.economia-page .h4,
.economia-page h5,
.economia-page .h5,
.economia-page h6,
.economia-page .h6 {
  color: #2b2f45;
  font-family: Poppins, "Helvetica Neue", Calibre, Helvetica, Arial, sans-serif;
}

.economia-page .mb--micro {
  margin-bottom: 8px !important;
}

.economia-page .text--large {
  font-size: 26px;
  line-height: 32px;
}

.economia-page .mb--small {
  margin-bottom: 16px !important;
}

.enercred-beneficios {
  background-color: #efefef;
  min-height: 100vh;
  display: -ms-flexbox;
  display: grid;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  /* background-color: #A4C425;  */
  color: #506670;
  font-size: 20px;
  /* padding-right: 32px;
        padding-left: 32px; */
  align-items: center;
}

.enercred-beneficios_left {
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.enercred-beneficios_img {
  padding-top: 100px;
  bottom: 0px;
}

.enercred-beneficios_icon {
  color: #a4c425;
}

.enercred-beneficios_line {
  padding: 15px 5px;
}

.enercred-clientes {
  /* background-color: #00a8bb; */
  background-color: #fff;
  /* color: #fff !important; */
  color: #506670;
  padding: 10px;
  /* color: #fff !important; */
  width: 100%;
  /* height: 100vh; */
  /* max-width: 1200px; */
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-direction: column; */
  flex-direction: column;
  /* -ms-flex-pack: center; */
  justify-content: center;
  font-size: 23px;
  /* padding-right: 15px; */
  /* padding-left: 15px; */
}

.enercred-clientes_btn {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  max-width: 90%;
}

h1 {
  font-size: 2rem !important;
  font-weight: 900;
}

h2 {
  font-size: 1.4rem !important;
}

p {
  font-size: 1.2rem !important;
}

.carousel_name {
  position: relative;
  top: 85%;
  background-color: #00a8bb;
  color: #fff;
  width: 50%;
  height: 30px;
  align-content: center;
}

.carousel_name p {
  vertical-align: middle;
  padding: 5px;
  font-size: 14px !important;
  font-weight: 400;
  height: 100%;
}

.enercred-footer {
  background-color: #506670;
  color: #fff;
  height: 10vh;
  font-weight: 500;
  padding: 3vh;
}

@media (min-width: 768px) {
  h2 {
    font-size: 2rem !important;
  }

  .logo {
    background-color: #fff;
    padding: 30px 96px;
  }

  #logo-color {
    max-width: 150px;
    width: 150px;
  }
  .enercred-indicacao {
    padding-left: 32px;
    padding-right: 32px;
  }

  .enercred-assinatura {
    padding-left: 32px;
    padding-right: 32px;
  }

  .enercred-beneficios {
    background-color: #efefef;
    min-height: 100vh;
    display: -ms-flexbox;
    display: grid;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    /* background-color: #A4C425;  */
    color: #506670;
    font-size: 23px;
    /* padding-right: 32px;
          padding-left: 32px; */
    align-items: center;
  }

  .carousel_name {
    position: relative;
    top: 85%;
    background-color: #00a8bb;
    color: #fff;
    width: 30%;
    height: 30px;
    align-content: center;
  }

  .carousel_name p {
    vertical-align: middle;
    padding: 5px;
    font-size: 14px !important;
    font-weight: 400;
    height: 100%;
  }

  .enercred-clientes {
    /* background-color: #00a8bb; */
    padding: 64px;
    /* color: #fff !important; */
    background-color: #fff;
    /* color: #fff !important; */
    color: #506670;
    width: 100%;
    /* height: 100vh; */
    /* max-width: 1200px; */
    /* display: -ms-flexbox; */
    display: flex;
    /* -ms-flex-direction: column; */
    flex-direction: column;
    /* -ms-flex-pack: center; */
    justify-content: center;
    font-size: 23px;
    /* padding-right: 15px; */
    /* padding-left: 15px; */
  }

  .enercred-clientes_rigth {
    padding-left: 15px;
  }

  .enercred-clientes_videos {
    padding: 10px;
    width: 33%;
    height: 247px;
  }
  .enercred-clientes_btn {
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 30%;
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .enercred-indicacao {
    /* min-height: 100vh; */
    background-color: #a4c425;
    color: #fff;
    padding-top: 50px;
    padding-bottom: 50px;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
  }

  .enercred-indicacao_left {
    text-align: center;
    padding-top: 0px;
    padding-right: 20px;
  }

  .enercred-indicacao_right {
    padding-left: 25px;
  }

  .enercred-indicacao_title {
    color: #fff !important;
  }

  .enercred-indicacao_ganhar {
    color: #2b2f45;
  }

  .enercred-assinatura {
    /* min-height: 100vh; */
    padding-top: 50px;
    padding-bottom: 50px;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
  }

  .enercred-assinatura_left {
    text-align: center;
    padding-right: 15px;
  }

  .enercred-assinatura_right {
    padding-left: 15px;
  }

  .enercred-assinatura_iframeContainer {
    width: 100%;
    max-width: 560px;
    height: 315px;
    /* margin-bottom: -8%; */
  }

  .enercred-assinatura_btn {
    width: 30%;
  }

  .enercred-assinatura_textContainer {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .enercred-assinatura_text {
    max-width: 568px;
  }

  /* .enercred-assinatura_imgContainer, .enercred-assinatura_textContainer {
            width: 50%;
        } */

  /* .enercred-assinatura_imgContainer, .enercred-assinatura_text {
            width: 50%;
        } */

  .economia-page .lg\:text--left {
    text-align: left;
  }

  .economia-page .text--large {
    font-size: 24px !important;
    line-height: 32px;
  }

  .enercred-beneficios_left {
    align-items: center;
    padding: 40px 32px;
    padding-left: 32px;
    justify-content: center;
  }
  .enercred-beneficios_right {
    padding-left: 32px;
  }

  h1 {
    font-size: 2.9rem !important;
  }

  p strong {
    /* font-size: 2.9rem !important; */
    font-weight: 900;
  }
}
</style>